import toast from "../services/toast";
import { helpers } from "vuelidate/lib/validators";
import axios from "axios";

export const truncate = (str, value) => {
  if (str) {
    let result =
      str.length > value
        ? str.substring(0, value) + "..."
        : str.substring(0, value);

    return result;
  } else {
    return " ";
  }
};

export const scrollToTop = () => window.scrollTo(0, 0);

export const copyClipboard = (val, msg) => {
  navigator.clipboard.writeText(val).then(() => toast.success(msg));
};

export const encodeObj = (obj) => {
  return encodeURI(JSON.stringify(obj));
};

export const decodeObj = (encodedObj) => {
  let stringfiedObj = decodeURI(encodedObj);
  return JSON.parse(stringfiedObj);
};

export const setURLParams = (paramName, paramValue) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  urlParams.set(paramName, paramValue);
  window.history.replaceState(null, null, "?" + urlParams.toString());
};

export const deepUrlValidation = (val) => {
  const expression =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  var regexUrl = new RegExp(expression);
  const isMatch = val.match(regexUrl);
  return !isMatch;
};

export const nonMandatoryUrlHelperValidation = (val) => {
  if (val == "") {
    return true;
  } else {
    const enteredValue =
      val.startsWith("https://") || val.startsWith("http://")
        ? val
        : `https://${val}`;
    const expression =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    //eslint-disable-next-line
    // const expression = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \#.-])\/?$/
    var regexUrl = new RegExp(expression);
    const isMatch = enteredValue.match(regexUrl);
    return !!isMatch;
  }
};

export const alphaNumericWithSpecialChars = helpers.regex(
  "alphaNumericWithSpecialChars",
  /^[a-zA-Z\s]+$/
);

export const urlValidation = helpers.regex(
  "urlValidation",
  /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
);

// eslint-disable-next-line no-useless-escape
export const alphaNumericWithSpecialCharsProjectName = helpers.regex(
  "alphaNumericWithSpecialCharsProjectName",
  // eslint-disable-next-line no-useless-escape
  /^[A-Za-z0-9\-_\(\) ]+$/
);

const cancelTokenSources = new Map();

export function createCancelToken(key) {
  if (cancelTokenSources.has(key)) {
    cancelTokenSources.get(key).cancel("Request canceled due to new request");
  }
  const source = axios.CancelToken.source();
  cancelTokenSources.set(key, source);
  return source.token;
}

export function cancelAllRequests() {
  cancelTokenSources.forEach((source, key) => {
    source.cancel("Request canceled due to route change or store update");
    cancelTokenSources.delete(key);
  });
}
